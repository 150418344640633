import { JWT_ACCESS_TOKEN } from "constants/JWT";
import { clearLocalstorage, getFromLocalstorage } from "./Localstorage";

export const API_ERRORS = {
  aborted: 'Request aborted',
  timedOut: 'Timed out'
}

// default timeouts on frontend: 30 seconds
function fetchWithTimeout(url, options, timeout = 120_000) {
  return Promise.race([
      fetch(url, options),
      new Promise((_, reject) =>
          setTimeout(() => reject(API_ERRORS.timedOut), timeout)
      )
  ]);
}

const FETCH_DEFAULTS = {
  method: "POST", // *GET, POST, PUT, DELETE, etc.
  mode: "cors", // no-cors, *cors, same-origin
  cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  credentials: "include", // include, *same-origin, omit
  redirect: "follow", // manual, *follow, error
  referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
};

const isLoginPage = () => {
  const URL = window.location.pathname;
  return URL === '/login';
};

const handle401 = (response: any) => {
  if (response.status === 401 && !isLoginPage()) {
    clearLocalstorage();
    window.location.href = "/";
  }
  return response;
};

const enforce200 = (response: any, allowServerResponse = false) => {
  if (response.status === 200 || response.status === 204) {
    return response;
  }

  if (!allowServerResponse) {
    throw new Error('Bad API response, check logs.');
  }

  return response;
};

function postData(url = "", data = {}, allowServerResponse = false, signal = undefined) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getFromLocalstorage(JWT_ACCESS_TOKEN)}`,
    },
    signal
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(handle401)
    .then(response => enforce200(response, allowServerResponse));
}

function putData(url = "", data = {}, signal = undefined) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getFromLocalstorage(JWT_ACCESS_TOKEN)}`,
    },
    signal
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(handle401)
    .then(enforce200);
}

function postFiles(url = "", data: any) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${getFromLocalstorage(JWT_ACCESS_TOKEN)}`,
    },
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(handle401)
    .then(enforce200);
}

function getData(url = "", signal = undefined) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getFromLocalstorage(JWT_ACCESS_TOKEN)}`,
    },
    signal
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(handle401)
    .then(enforce200);
}

function patchData(url = "", data = {}, signal = undefined) {
  const fetchOptions = Object.assign({}, FETCH_DEFAULTS, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getFromLocalstorage(JWT_ACCESS_TOKEN)}`,
    },
    signal
  });

  return fetchWithTimeout(url, fetchOptions)
    .then(handle401)
    .then(enforce200);
}

const API = {
  get: getData,
  post: postData,
  patch: patchData,
  put: putData,
  postFiles
};

export default API;
