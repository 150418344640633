// /frontend/src/pages/settings/UserKnowledge.tsx

import React, { ChangeEvent, FormEvent } from "react";
import { inject, observer } from "mobx-react";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';

import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from "components/common/BasePage";
import BaseForm from "components/forms/BaseForm";
import Tabs from "components/common/Tab";
import Card from "components/common/Card";
import Progress from "components/common/Progress";
import { HeadText, Text } from "components/common/Typography";

import "../../brand/index.scss";

interface UserKnowledgePageProps {
    UserStore?: any;
}

interface UserKnowledgePageState {
    homeAddress: string | undefined;
    officeAddress: string | undefined;
    preferredHomeAirport: string | undefined;
    preferredAirline: string | undefined;
    favoriteCuisines: string | undefined;
    favoriteCuisinesWeekdays: string | undefined;
    atlasTopFiveAirlines: string | undefined;
    isLoading: boolean;
    autoSaving: boolean;
}

class UserKnowledgePage extends React.Component<UserKnowledgePageProps, UserKnowledgePageState> {
    initialValues: Partial<UserKnowledgePageState> = {};

    state: UserKnowledgePageState = {
        homeAddress: '',
        officeAddress: '',
        preferredHomeAirport: '',
        preferredAirline: '',
        favoriteCuisines: '',
        favoriteCuisinesWeekdays: '',
        atlasTopFiveAirlines: '',
        isLoading: true,
        autoSaving: false
    };

    debouncedSave = debounce((updatedMetadata: any) => {
        const { UserStore } = this.props;
        
        this.setState({ autoSaving: true });
        
        UserStore.updateUserMetadata(updatedMetadata)
            .then((response: any) => {
                if (response && response.metadata) {
                    const newState = {
                        homeAddress: response.metadata.homeAddress ?? '',
                        officeAddress: response.metadata.officeAddress ?? '',
                        preferredHomeAirport: response.metadata.preferredHomeAirport ?? '',
                        preferredAirline: response.metadata.preferredAirline ?? '',
                        favoriteCuisines: response.metadata.favoriteCuisines ?? '',
                        favoriteCuisinesWeekdays: response.metadata.favoriteCuisinesWeekdays ?? '',
                        atlasTopFiveAirlines: response.metadata.atlasTopFiveAirlines ?? '',
                    };

                    this.setState(newState);
                    this.initialValues = { ...newState };
                }
                //toast.success("Preferences auto-saved", { icon: false });
            })
            .catch((error: any) => {
                console.error('Auto-save failed:', error);
                toast.error("Failed to auto-save preferences", { icon: false });
            })
            .finally(() => {
                this.setState({ autoSaving: false });
            });
    }, 1000);

    componentDidMount(): void {
        const { UserStore } = this.props;
        
        UserStore.loadUserMetadata().then(() => {
            const initialState = {
                homeAddress: UserStore.metadata.homeAddress ?? '',
                officeAddress: UserStore.metadata.officeAddress ?? '',
                preferredHomeAirport: UserStore.metadata.preferredHomeAirport ?? '',
                preferredAirline: UserStore.metadata.preferredAirline ?? '',
                favoriteCuisines: UserStore.metadata.favoriteCuisines ?? '',
                favoriteCuisinesWeekdays: UserStore.metadata.favoriteCuisinesWeekdays ?? '',
                atlasTopFiveAirlines: UserStore.metadata.atlasTopFiveAirlines ?? '',
            };

            this.initialValues = { ...initialState };
            this.setState({ ...initialState, isLoading: false });
        });
    }

    componentWillUnmount() {
        this.debouncedSave.cancel();
    }

    onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        
        this.setState(prevState => {
            const newState = { ...prevState, [name]: value };
            
            if (value !== this.initialValues[name as keyof typeof this.initialValues]) {
                const updatedMetadata = {
                    homeAddress: newState.homeAddress,
                    officeAddress: newState.officeAddress,
                    preferredHomeAirport: newState.preferredHomeAirport,
                    preferredAirline: newState.preferredAirline,
                    favoriteCuisines: newState.favoriteCuisines,
                    favoriteCuisinesWeekdays: newState.favoriteCuisinesWeekdays,
                    atlasTopFiveAirlines: newState.atlasTopFiveAirlines
                };
                this.debouncedSave(updatedMetadata);
            }
            
            return newState;
        });
    };

    onSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { UserStore } = this.props;
        const { 
            homeAddress, 
            officeAddress, 
            preferredHomeAirport, 
            preferredAirline, 
            favoriteCuisines,
            favoriteCuisinesWeekdays,
            atlasTopFiveAirlines
        } = this.state;

        const updatedMetadata = {
            homeAddress,
            officeAddress,
            preferredHomeAirport,
            preferredAirline,
            favoriteCuisines,
            favoriteCuisinesWeekdays,
            atlasTopFiveAirlines
        };

        UserStore.updateUserMetadata(updatedMetadata)
            .then((response: any) => {
                if (response && response.metadata) {
                    const newState = {
                        homeAddress: response.metadata.homeAddress ?? '',
                        officeAddress: response.metadata.officeAddress ?? '',
                        preferredHomeAirport: response.metadata.preferredHomeAirport ?? '',
                        preferredAirline: response.metadata.preferredAirline ?? '',
                        favoriteCuisines: response.metadata.favoriteCuisines ?? '',
                        favoriteCuisinesWeekdays: response.metadata.favoriteCuisinesWeekdays ?? '',
                        atlasTopFiveAirlines: response.metadata.atlasTopFiveAirlines ?? '',
                    };

                    this.setState(newState);
                    this.initialValues = { ...newState };
                }
                toast.success("Your preferences have been updated successfully.", { icon: false });
            })
            .catch((error: any) => {
                console.error('Update failed:', error);
                toast.error("Oops! Something went wrong, please try again.", { icon: false });
            });
    };

    render() {
        const { UserStore } = this.props;
        const { 
            homeAddress, 
            officeAddress, 
            preferredHomeAirport, 
            preferredAirline, 
            favoriteCuisines, 
            favoriteCuisinesWeekdays,
            atlasTopFiveAirlines,
            isLoading,
            autoSaving 
        } = this.state;

        const config = [
            {
                labelText: "Home Address",
                fieldId: "homeAddress",
                type: "suggestion",
                value: homeAddress,
                required: false,
            },
            {
                labelText: "Office Address",
                fieldId: "officeAddress",
                type: "suggestion",
                value: officeAddress,
                required: false,
            },
            {
                labelText: "Preferred Home Airport",
                fieldId: "preferredHomeAirport",
                type: "suggestion",
                value: preferredHomeAirport,
                required: false
            },
            {
                labelText: "Preferred Airline",
                fieldId: "preferredAirline",
                type: "suggestion",
                value: preferredAirline,
                required: false
            },
            {
                labelText: "Favorite Cuisines",
                fieldId: "favoriteCuisines",
                type: "suggestion",
                value: favoriteCuisines,
                required: false,
            },
            {
                labelText: "Favorite Weekday Cuisines",
                fieldId: "favoriteCuisinesWeekdays",
                type: "suggestion",
                value: favoriteCuisinesWeekdays,
                required: false,
            },
            {
                labelText: "Atlas Example",
                fieldId: "atlasTopFiveAirlines",
                type: "suggestion",
                value: atlasTopFiveAirlines,
                required: false,
            }
        ];

        const tabs = [
            { title: 'Profile', url: '/settings/profile' },
            { title: 'Details', url: '/settings/user-knowledge', active: true },
            { title: 'Password', url: '/settings/password' },
            { title: 'Accounts', url: '/settings/accounts' },
        ];

        return (
            <AuthenticatedPage>
                <BasePage>
                    <div className="adapter-fullscreen-page">
                        <Tabs tabs={tabs} />
                        <div style={{ width: '80vw', maxWidth: '600px', alignSelf: 'center' }}>
                            <HeadText fontSize="xs" cls='adapter-font-color-primary-900' fontWeight='bold'>
                                User Knowledge
                            </HeadText>
                            <Text>
                                Configure facts or preferences about you to enhance your user experience.
                                {autoSaving && <span style={{ color: '#666', marginLeft: '10px' }}>(Auto-saving...)</span>}
                            </Text>
                            <Card>
                                {!isLoading && (
                                    <BaseForm
                                        config={config}
                                        isLoading={UserStore.isLoading}
                                        buttonText="Save Changes"
                                        onChange={this.onChangeHandler}
                                        onSubmit={this.onSubmitHandler}
                                        style={{ minWidth: '50%' }}
                                    />
                                )}
                                {isLoading && (<Progress />)}
                            </Card>
                        </div>
                        <div className="flex-1"></div>
                    </div>
                </BasePage>
            </AuthenticatedPage>
        );
    }
}

export default inject("UserStore")(observer(UserKnowledgePage));