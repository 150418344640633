// /frontend/src/pages/atlas/index.tsx
import React from "react";
import { toast } from "react-toastify";
import { inject, observer } from "mobx-react";
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';

// Store imports
import atlasStore from "../../stores/AtlasStore";

// Component imports
import AuthenticatedPage from "components/common/AuthenticatedPage";
import BasePage from 'components/common/BasePage';
import { CategoryList } from './CategoryList';
import { TileTypeList } from './TileTypeList';
import { PreferenceList } from './PreferenceList';
import { PreferenceDetail } from './PreferenceDetail';

import { 
    determineTileType, 
    getTileTypeName, 
    getTileTypeIcon,
    CATEGORY_MAPPINGS 
} from '../../constants/CategoryMappings';



// Styled Components
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  min-height: 100vh;
  background-color: #edebfa;
  width: 100%;
  max-width: none; // Remove any max-width constraint
`;


// Back button with animation
const BackButton = styled(motion.button)`
  border: none;
  background: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-family: "Rand Medium", sans-serif;
  transition: all 0.2s ease;
  
  &::before {
    content: '←';
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #333;
    &::before {
      transform: translateX(-4px);
    }
  }
`;

// Animated section container
const Section = styled(motion.div)`
  margin-bottom: 24px;
  width: 100%;
  max-width: none; // Remove any max-width constraint
  background-color: #edebfa;
`;

// Breadcrumb navigation container
const BreadcrumbNav = styled.nav`
  padding: 0 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BreadcrumbItem = styled.span<{ isActive?: boolean }>`
  color: ${props => props.isActive ? '#333' : '#666'};
  font-size: 14px;
  cursor: ${props => props.isActive ? 'default' : 'pointer'};
  
  &:hover {
    color: ${props => props.isActive ? '#333' : '#000'};
  }
`;

const BreadcrumbSeparator = styled.span`
  color: #999;
  margin: 0 4px;
`;

// Animation variants
const pageTransition = {
  initial: { opacity: 0, x: 20 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
  transition: { duration: 0.3 }
};

const LoadingSpinner = () => (
    <div style={{ 
        width: '40px', 
        height: '40px', 
        border: '4px solid #f3f3f3',
        borderTop: '4px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
    }} />
);

// Interfaces for Atlas data
interface AtlasPreference {
    id: string;
    name: string;
    value: any;
    category: string;
    subCategory?: string;
    metadata?: Record<string, any>;
    confidence?: number;
    lastUpdated?: string;
    source?: string;
}

// In AtlasPage, update the AtlasCategoryData interface
interface AtlasCategoryData {
    category: string;
    preferences: AtlasPreference[];
    metadata?: {
        lastUpdated?: string;
        confidence?: number;
        source?: string;
    };
    suggestedText?: string; // Add this line
}


interface AtlasCache {
    [key: string]: {
        data: AtlasCategoryData;
        timestamp: number;
        expiresAt: number;
    };
}

interface AtlasPageState {
    selectedCategory: string | null;
    selectedTileType: string | null;
    selectedPreference: string | null;
    selectedCategoryType: string | null;
    breadcrumbs: string[];
    navigationHistory: Array<{ type: 'category' | 'tiletype' | 'preference', id: string }>;
    isLoading: boolean;
    isLoadingMemories: boolean;
    isLoadingPreferences: boolean;
    retryCount: number;
    atlasCache: AtlasCache;
    memories: Array<{
        id: string;
        categoryId: string;
        categoryType: string;
        tileType: string;
        name: string;
        value: string;
        metadata?: {
            date?: string;
            source?: string;
            confidence?: number;
            [key: string]: any;
        };
    }>;
    error: string | null;
    lastUpdated: Record<string, number>;
}

interface AtlasPageProps {
    AtlasStore?: {
        fetchAtlasPreference: (category: string) => Promise<AtlasCategoryData>;
        updateAtlasPreference: (category: string, preferenceId: string, value: any) => Promise<void>;
        fetchMemories: (category?: string) => Promise<any[]>;
        getFilteredMemories: (category: string, tileType?: string) => any[];
        isLoadingMemories: boolean;
        memoriesError: string | null;
        memories: any[];
    };
}

interface UnderstandingItem {
    title: string;
    value: any;
    type: "text" | "percentage" | "memory";
}

// Add these props interfaces for the list components
interface TileTypeListProps {
    categoryType: string;
    tileTypes: Array<{
        id: string;
        name: string;
        icon: string;
        count?: number;
    }>;
    atlasData: AtlasCache;
    onTileTypeSelect: (id: string) => void;
}


interface PreferenceListProps {
    preferences: Array<{
        id: string;
        name: string;
        value: any;
        metadata?: Record<string, any>;
    }>;
    onPreferenceSelect: (id: string) => void;
    maxColumns: number;
    minTileSize: number;
}

// Constants
const CACHE_DURATION = 1000 * 60 * 15; // 15 minutes
const MAX_RETRY_ATTEMPTS = 1;
const RETRY_DELAY = 1000; // 1 second

// Sample data import (keep your existing sample data)
export const sampleData = {
  // /frontend/src/constants/index.ts

categories: [
    {
      id: '1',
      type: 'Flights',
      name: 'Flights',
      icon: '✈️',
      description: 'Travel Preferences',
      tileTypes: [
        { id: 'airline', name: 'Airlines', icon: '✈️' },
        { id: 'price', name: 'Price & Budget', icon: '💰' },
        { id: 'timing', name: 'Time & Schedule', icon: '⏰' },
        { id: 'location', name: 'Airports & Routes', icon: '📍' },
        { id: 'comfort', name: 'Comfort & Class', icon: '💺' },
        { id: 'loyalty', name: 'Loyalty Programs', icon: '🎯' }
      ]
    },
    {
      id: '2',
      type: 'Dining',
      name: 'Dining',
      icon: '🍽️',
      description: 'Dining Preferences',
      tileTypes: [
        { id: 'cuisine', name: 'Cuisine Types', icon: '🍳' },
        { id: 'price', name: 'Price Range', icon: '💰' },
        { id: 'dietary', name: 'Dietary Needs', icon: '🥗' },
        { id: 'atmosphere', name: 'Atmosphere', icon: '🌟' },
        { id: 'location', name: 'Location & Distance', icon: '📍' },
        { id: 'timing', name: 'Dining Times', icon: '⏰' }
      ]
    },
    {
      id: '3',
      type: 'Lodging',
      name: 'Lodging',
      icon: '🏨',
      description: 'Accommodation Preferences',
      tileTypes: [
        { id: 'accommodation', name: 'Property Types', icon: '🏨' },
        { id: 'comfort', name: 'Amenities', icon: '🛏️' },
        { id: 'services', name: 'Services', icon: '🛎️' },
        { id: 'location', name: 'Location & Area', icon: '📍' },
        { id: 'price', name: 'Rates & Budget', icon: '💰' },
        { id: 'loyalty', name: 'Hotel Programs', icon: '🎯' },
        { id: 'timing', name: 'Stay Patterns', icon: '⏰' },
        { id: 'social', name: 'Group & Events', icon: '👥' }
      ]
    }
  ],
  preferences: [
    // Travel Preferences
    {
      id: 'travel-1',
      categoryId: '1',
      categoryType: 'Travel',
      tileType: 'airline',
      name: 'Primary Airline',
      value: 'Delta Airlines',
      location: { code: 'JFK' },
      metadata: { membership: 'Diamond Medallion' }
    },
    {
      id: 'travel-2',
      categoryId: '1',
      categoryType: 'Travel',
      tileType: 'airline',
      name: 'Secondary Airline',
      value: 'United Airlines',
      location: { code: 'AUS' },
      metadata: { membership: 'Gold' }
    },
    {
      id: 'travel-3',
      categoryId: '1',
      categoryType: 'Travel',
      tileType: 'price',
      name: 'Average Ticket Budget',
      value: '$750',
      metadata: { scope: 'Domestic' }
    },
    {
      id: 'travel-4',
      categoryId: '1',
      categoryType: 'Travel',
      tileType: 'timing',
      name: 'Preferred Departure',
      value: '8am - 11am',
      metadata: { days: 'Weekday' }
    },
    {
      id: 'travel-5',
      categoryId: '1',
      categoryType: 'Travel',
      tileType: 'comfort',
      name: 'Seat Preference',
      value: 'Window',
      metadata: { position: 'Forward Cabin' }
    },

    // Dining Preferences
    {
      id: 'food-1',
      categoryId: '3',
      categoryType: 'Food',
      tileType: 'cuisine',
      name: 'Favorite Cuisine',
      value: 'Italian',
      metadata: { specifics: 'Northern Italian' }
    },
    {
      id: 'food-2',
      categoryId: '3',
      categoryType: 'Food',
      tileType: 'price',
      name: 'Dining Budget',
      value: '$$$',
      metadata: { mealType: 'Dinner' }
    },
    {
      id: 'food-3',
      categoryId: '3',
      categoryType: 'Food',
      tileType: 'dietary',
      name: 'Dietary Restriction',
      value: 'Gluten-Free',
      metadata: { severity: 'Strict' }
    },
  ]
};


class AtlasPage extends React.Component<AtlasPageProps, AtlasPageState> {
    // Cache management
    private cacheTimeouts: Record<string, NodeJS.Timeout> = {};
    private retryTimeouts: Record<string, NodeJS.Timeout> = {};

    state: AtlasPageState = {
        selectedCategory: null,
        selectedTileType: null,
        selectedPreference: null,
        selectedCategoryType: null,
        breadcrumbs: [],
        navigationHistory: [],
        isLoading: false,
        isLoadingMemories: false,
        isLoadingPreferences: false,
        retryCount: 0,
        atlasCache: {},
        memories: [],
        error: null,
        lastUpdated: {}
    };

    componentDidMount() {
        this.loadInitialData();
    }

    componentWillUnmount() {
        Object.values(this.cacheTimeouts).forEach(clearTimeout);
        Object.values(this.retryTimeouts).forEach(clearTimeout);
    }

    loadInitialData = async () => {
        try {
            this.setState({ isLoading: true });
            
            const categories = ['Flights', 'Dining', 'Lodging'];
            const results = await Promise.allSettled(
                categories
                    .filter(category => this.shouldFetchCategory(category))
                    .map(async category => ({
                        category,
                        data: await this.fetchAtlasData(category, true)
                    }))
            );

            const errors = results
                .filter(result => result.status === 'rejected')
                .map(result => (result as PromiseRejectedResult).reason);

            const successes = results
                .filter(result => result.status === 'fulfilled')
                .map(result => (result as PromiseFulfilledResult<any>).value);

            this.setState({ 
                isLoading: false,
                error: errors.length > 0 ? 'Some categories failed to load' : null
            });

            // Log detailed results if there are errors
            if (errors.length > 0) {
                console.error(`Failed to load ${errors.length} of ${categories.length} categories:`, errors);
                toast.error(`Failed to load ${errors.length} categories`);
            }
            
        } catch (error) {
            console.error('Critical failure in loadInitialData:', error);
            this.setState({ 
                error: 'Failed to load initial data', 
                isLoading: false 
            });
            toast.error('Failed to load initial data');
        }
    };



    private shouldFetchCategory = (category: string): boolean => {
        const cachedData = this.state.atlasCache[category];
        if (!cachedData) return true;
        const now = Date.now();
        return now >= cachedData.expiresAt;
    };

    private setCachedData = (category: string, data: AtlasCategoryData) => {
        const now = Date.now();
        const newCache = {
            ...this.state.atlasCache,
            [category]: {
                data,
                timestamp: now,
                expiresAt: now + CACHE_DURATION
            }
        };

        if (this.cacheTimeouts[category]) {
            clearTimeout(this.cacheTimeouts[category]);
        }

        this.cacheTimeouts[category] = setTimeout(() => {
            this.setState(prevState => ({
                atlasCache: {
                    ...prevState.atlasCache,
                    [category]: undefined
                }
            }));
        }, CACHE_DURATION);

        this.setState({ 
            atlasCache: newCache,
            lastUpdated: {
                ...this.state.lastUpdated,
                [category]: now
            }
        });
    };

    fetchAtlasData = async (category: string, silent: boolean = false) => {
        if (!silent) {
            this.setState({ isLoadingPreferences: true });
        }

        const attempt = async (retryCount: number = 0) => {
            try {
                const data = await this.props.AtlasStore.fetchAtlasPreference(category);
                this.setCachedData(category, data);
                
                if (!silent) {
                    this.setState({ 
                        isLoadingPreferences: false,
                        retryCount: 0
                    });
                }
                
                return data;
            } catch (error) {
                if (retryCount < MAX_RETRY_ATTEMPTS) {
                    if (this.retryTimeouts[category]) {
                        clearTimeout(this.retryTimeouts[category]);
                    }

                    this.retryTimeouts[category] = setTimeout(() => {
                        attempt(retryCount + 1);
                    }, RETRY_DELAY * Math.pow(2, retryCount));

                    if (!silent) {
                        this.setState({ retryCount: retryCount + 1 });
                    }
                } else {
                    if (!silent) {
                        this.setState({ 
                            isLoadingPreferences: false,
                            error: 'Failed to load preferences after multiple attempts',
                            retryCount: 0
                        });
                    }
                    throw error;
                }
            }
        };

        return attempt();
    };

    handleCategorySelect = async (id: string) => {
        try {
            this.setState({ isLoading: true });
            
            const category = sampleData.categories.find(c => c.id === id);
            if (!category) throw new Error('Category not found');

            if (category.type != 'Memories') {
                if (this.shouldFetchCategory(category.type)) {
                    await this.fetchAtlasData(category.type);
                }
            } else if (category.type === 'Memories') {
                await this.props.AtlasStore.fetchMemories();
            }

            this.setState({
                selectedCategory: id,
                selectedTileType: null,
                selectedPreference: null,
                breadcrumbs: [category.name],
                navigationHistory: [...this.state.navigationHistory, { type: 'category', id }],
                isLoading: false,
                error: null
            });
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to load category';
            toast.error(errorMessage);
            this.setState({ 
                error: errorMessage, 
                isLoading: false 
            });
        }
    };

    handleTileTypeSelect = async (typeId: string) => {
        try {
            this.setState({ isLoading: true });
            
            const category = sampleData.categories.find(c => c.id === this.state.selectedCategory);
            const tileType = this.getCurrentTileTypes().find(t => t.id === typeId);
            
            if (!tileType) throw new Error('Tile type not found');

            if (category.type != 'Memories') {
                if (this.shouldFetchCategory(category.type)) {
                    await this.fetchAtlasData(category.type);
                }
            } else if (category?.type === 'Memories') {
                await this.props.AtlasStore.fetchMemories(tileType.id);
            }

            this.setState(prevState => ({
                selectedTileType: typeId,
                selectedPreference: null,
                breadcrumbs: [...prevState.breadcrumbs, tileType.name],
                navigationHistory: [...prevState.navigationHistory, { type: 'tiletype', id: typeId }],
                isLoading: false,
                error: null
            }));
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to load tile type';
            toast.error(errorMessage);
            this.setState({ 
                error: errorMessage, 
                isLoading: false 
            });
        }
    };

    handlePreferenceSelect = async (id: string) => {
        try {
            this.setState({ isLoading: true });
            
            const preference = this.getCurrentPreferences().find(p => p.id === id);
            if (!preference) throw new Error('Preference not found');

            if (preference.id.startsWith('atlas-')) {
                const category = sampleData.categories.find(c => c.id === this.state.selectedCategory);
                if (category && this.shouldFetchCategory(category.type)) {
                    await this.fetchAtlasData(category.type);
                }
            }

            this.setState(prevState => ({
                selectedPreference: id,
                breadcrumbs: [...prevState.breadcrumbs, preference.name],
                navigationHistory: [...prevState.navigationHistory, { type: 'preference', id }],
                isLoading: false,
                error: null
            }));
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to load preference';
            toast.error(errorMessage);
            this.setState({ 
                error: errorMessage, 
                isLoading: false 
            });
        }
    };

    handleBack = () => {
        this.setState(prevState => {
            const newHistory = [...prevState.navigationHistory];
            newHistory.pop();
            const lastItem = newHistory[newHistory.length - 1];

            const baseState = {
                ...prevState,
                navigationHistory: newHistory,
                breadcrumbs: prevState.breadcrumbs.slice(0, -1),
                error: null,
                isLoading: false
            };

            if (!lastItem) {
                return {
                    ...baseState,
                    selectedCategory: null,
                    selectedTileType: null,
                    selectedPreference: null,
                    breadcrumbs: [],
                    navigationHistory: [],
                    memories: []
                };
            }

            if (lastItem.type === 'category') {
                return {
                    ...baseState,
                    selectedCategory: lastItem.id,
                    selectedTileType: null,
                    selectedPreference: null
                };
            }

            if (lastItem.type === 'tiletype') {
                return {
                    ...baseState,
                    selectedTileType: lastItem.id,
                    selectedPreference: null
                };
            }

            return {
                ...baseState,
                selectedPreference: lastItem.id
            };
        });
    };

    handleBreadcrumbClick = (index: number) => {
        // If clicking "Home" (index is -1), reset everything
        if (index === -1) {
            this.setState({
                selectedCategory: null,
                selectedTileType: null,
                selectedPreference: null,
                breadcrumbs: [],
                navigationHistory: [],
                isLoading: false,
                error: null
            });
            return;
        }

        // Otherwise, handle normal breadcrumb navigation
        this.setState(prevState => {
            const newHistory = prevState.navigationHistory.slice(0, index + 1);
            const newBreadcrumbs = prevState.breadcrumbs.slice(0, index + 1);
            const lastItem = newHistory[newHistory.length - 1];

            return {
                ...prevState,
                navigationHistory: newHistory,
                breadcrumbs: newBreadcrumbs,
                selectedCategory: lastItem?.type === 'category' ? lastItem.id : prevState.selectedCategory,
                selectedTileType: lastItem?.type === 'tiletype' ? lastItem.id : null,
                selectedPreference: lastItem?.type === 'preference' ? lastItem.id : null,
                isLoading: false,
                error: null
            };
        });
    };


    getCurrentTileTypes = () => {
        const category = sampleData.categories.find(
            c => c.id === this.state.selectedCategory
        );

        if (category?.type !== 'Memories') {
            const cachedData = this.state.atlasCache[category?.type]?.data;
            if (cachedData) {
                const tileTypes = new Map();
                cachedData.preferences.forEach(pref => {
                    const tileType = determineTileType(pref.name);
                    if (!tileTypes.has(tileType)) {
                        tileTypes.set(tileType, {
                            id: tileType,
                            name: getTileTypeName(tileType),
                            icon: getTileTypeIcon(tileType),
                            count: 0
                        });
                    }
                    tileTypes.get(tileType).count++;
                });
                return Array.from(tileTypes.values());
            }
        }

        return category?.tileTypes || [];
    };


    getCurrentPreferences = () => {
        const selectedCategory = sampleData.categories.find(
            c => c.id === this.state.selectedCategory
        );
        
        if (selectedCategory?.type != 'Memories') {
            const cachedData = this.state.atlasCache[selectedCategory?.type]?.data;
            if (cachedData) {
                return this.transformAtlasData(cachedData).filter(
                    pref => pref.tileType === this.state.selectedTileType
                );
            }
            return [];
        }
        
        if (selectedCategory?.type === 'Memories') {
            return this.props.AtlasStore.getFilteredMemories(
                'Memories',
                this.state.selectedTileType
            );
        }
        
        return sampleData.preferences.filter(p => 
            p.categoryType === selectedCategory?.type && 
            p.tileType === this.state.selectedTileType
        );
    };

    transformAtlasData = (data: AtlasCategoryData) => {
        return data.preferences.map((pref, index) => ({
            id: `atlas-${data.category}-${index}`,
            categoryId: '1',
            categoryType: data.category,
            tileType: determineTileType(pref.name),
            name: pref.name,
            value: pref.value,
            metadata: {
                ...pref.metadata,
                confidence: pref.confidence,
                lastUpdated: pref.lastUpdated,
                source: pref.source
            }
        }));
    };


    private debouncedUpdatePreference = debounce(async (
        category: string,
        preferenceId: string,
        value: any
    ) => {
        try {
            await this.props.AtlasStore.updateAtlasPreference(
                category,
                preferenceId,
                value
            );
            toast.success('Preference updated successfully');
        } catch (error) {
            toast.error('Failed to update preference');
        }
    }, 500);

    render() {
        var selectedCategoryType;
        const { 
            selectedCategory, 
            selectedTileType, 
            selectedPreference, 
            isLoading,
            isLoadingPreferences,
            error 
        } = this.state;

        if (selectedCategory == "1") {
            selectedCategoryType = 'Flights'
        } else if (selectedCategory == "2") {
            selectedCategoryType = 'Dining'
        } else if (selectedCategory == "3") {
            selectedCategoryType = 'Lodging'
        }

        const isMemoryCategory = selectedCategory === '6';
        const isLoadingMemories = isMemoryCategory && this.props.AtlasStore.isLoadingMemories;
        const memoryError = isMemoryCategory ? this.props.AtlasStore.memoriesError : null;

        if (error || memoryError) {
            return (
                <ContentContainer>
                    {this.renderBreadcrumbs()}
                    {this.renderError(error || memoryError)}
                </ContentContainer>
            );
        }

        return (
            <AuthenticatedPage>
                <BasePage>
                    <ContentContainer>
                        {this.renderBreadcrumbs()}
                        
                        {this.state.breadcrumbs.length > 0 && (
                            <BackButton 
                                onClick={this.handleBack}
                                whileHover={{ x: -4 }}
                                whileTap={{ scale: 0.98 }}
                            >
                                                                Back to {this.state.breadcrumbs[this.state.breadcrumbs.length - 2] || 'Categories'}
                            </BackButton>
                        )}
                        
                        <AnimatePresence mode="wait">
                            {(isLoading || isLoadingMemories || isLoadingPreferences) ? (
                                this.renderLoading()
                            ) : (
                                <Section
                                    key={`view-${selectedCategory}-${selectedTileType}-${selectedPreference}`}
                                    {...pageTransition}
                                >
                                    {!selectedCategory && (
                                        <CategoryList
                                            categories={sampleData.categories}
                                            onCategorySelect={this.handleCategorySelect}
                                            maxColumns={4}
                                            minTileSize={300}
                                            selectedCategory={selectedCategory}
                                        />
                                    )}

                                    {selectedCategory && !selectedTileType && (
                                        <TileTypeList
                                            categoryType={selectedCategoryType}
                                            tileTypes={this.getCurrentTileTypes()}
                                            atlasData={Object.keys(this.state.atlasCache).reduce((acc, key) => ({
                                                ...acc,
                                                [key]: {
                                                    category: key,
                                                    preferences: this.state.atlasCache[key].data.preferences,
                                                    suggestedText: this.state.atlasCache[key].data.suggestedText
                                                }
                                            }), {})}
                                            onTileTypeSelect={this.handleTileTypeSelect}
                                        />
                                    )}




                                    {selectedCategory && selectedTileType && !selectedPreference && (
                                        <PreferenceList
                                            preferences={this.getCurrentPreferences()}
                                            onPreferenceSelect={this.handlePreferenceSelect}
                                            maxColumns={4}
                                            minTileSize={300}
                                        />
                                    )}

                                    {selectedPreference && (
                                        <PreferenceDetail
                                            preference={this.getCurrentPreferences().find(
                                                p => p.id === selectedPreference
                                            )!}
                                            history={this.getPreferenceHistory(selectedPreference)}
                                            understanding={this.getPreferenceUnderstanding(selectedPreference)}
                                            onBack={this.handleBack}
                                        />
                                    )}
                                </Section>
                            )}
                        </AnimatePresence>
                    </ContentContainer>
                </BasePage>
            </AuthenticatedPage>
        );
    }

    private renderBreadcrumbs = () => (
        <BreadcrumbNav>
            <BreadcrumbItem onClick={() => this.handleBreadcrumbClick(-1)}>
                Home
            </BreadcrumbItem>
            {this.state.breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                    <BreadcrumbSeparator>›</BreadcrumbSeparator>
                    <BreadcrumbItem 
                        onClick={() => this.handleBreadcrumbClick(index)}
                        isActive={index === this.state.breadcrumbs.length - 1}
                    >
                        {crumb}
                        {this.renderBreadcrumbMeta(index)}
                    </BreadcrumbItem>
                </React.Fragment>
            ))}
        </BreadcrumbNav>
    );

    private renderBreadcrumbMeta = (index: number) => {
        const { selectedCategory } = this.state;
        if (index === 0 && selectedCategory) {
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            if (category?.type === 'Travel' || category?.type === 'Dining') {
                const lastUpdated = this.state.lastUpdated[category?.type];
                if (lastUpdated) {
                    return (
                        <span style={{ fontSize: '12px', color: '#666', marginLeft: '8px' }}>
                            (Updated {new Date(lastUpdated).toLocaleTimeString()})
                        </span>
                    );
                }
            }
        }
        return null;
    };

    private renderLoading = () => (
        <Section key="loading" {...pageTransition}>
            <div style={{ 
                padding: 24, 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: '12px'
            }}>
                <LoadingSpinner />
                {this.state.retryCount > 0 && (
                    <div style={{ color: '#666', fontSize: '14px' }}>
                        Retrying... (Attempt {this.state.retryCount} of {MAX_RETRY_ATTEMPTS})
                    </div>
                )}
            </div>
        </Section>
    );

    private renderError = (error: string) => (
        <Section key="error" {...pageTransition}>
            <div style={{ 
                padding: 24, 
                color: 'red',
                display: 'flex',
                flexDirection: 'column',
                gap: '12px' 
            }}>
                <div>{error}</div>
                <button 
                    onClick={this.handleRetry}
                    style={{
                        padding: '8px 16px',
                        border: 'none',
                        borderRadius: '4px',
                        background: '#f0f0f0',
                        cursor: 'pointer'
                    }}
                >
                    Retry
                </button>
            </div>
        </Section>
    );

    private getPreferenceHistory = (preferenceId: string) => {
        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            return [{
                provider: 'Atlas Service',
                date: preference?.metadata?.lastUpdated || new Date().toISOString(),
                confidence: preference?.metadata?.confidence
            }];
        }
        return [];
    };

    private getPreferenceUnderstanding = (preferenceId: string): UnderstandingItem[] => {
        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            return [
                {
                    title: 'Source',
                    value: preference?.metadata?.source || 'Atlas Inference',
                    type: "text"
                },
                {
                    title: 'Confidence',
                    value: preference?.metadata?.confidence || 0,
                    type: "percentage"
                }
            ];
        }
        return [];
    };

    private handlePreferenceUpdate = async (preferenceId: string, value: any) => {
        if (preferenceId.startsWith('atlas-')) {
            const preference = this.getCurrentPreferences().find(p => p.id === preferenceId);
            if (preference) {
                await this.debouncedUpdatePreference(
                    preference.categoryType,
                    preferenceId,
                    value
                );
            }
        }
    };

    private handleRetry = () => {
        const { selectedCategory } = this.state;
        if (selectedCategory) {
            const category = sampleData.categories.find(c => c.id === selectedCategory);
            if (category?.type === 'Travel' || category?.type === 'Dining') {
                this.fetchAtlasData(category?.type);
            }
        }
    };
}

export default inject("AtlasStore")(observer(AtlasPage));