// /frontend/src/stores/UserStore.ts

import { makeObservable, observable, action, computed } from "mobx";

import API from "util/API";

class UserStore {
  public user: null;
  public metadata: any = {};
  public userAccounts: any[] = undefined;

  public isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      user: observable,
      metadata: observable,
      userAccounts: observable,
      isLoading: observable,
      isOnboarding: computed,
      hasTakenTour: computed,

      afterCompleteTour: action,

      loadUserAccounts: action,
      loadUserAccountsSuccess: action,
      loadUserAccountsFailure: action,

      loadUser: action,
      loadUserSuccess: action,
      loadUserFailure: action,

      updateUser: action,
      updateUserSuccess: action,
      updateUserFailure: action,

      sendFeedback: action,
      sendFeedbackSuccess: action,
      sendFeedbackFailure: action,

      uploadFileChunk: action,
      uploadFileChunkFailure: action,
      uploadFileChunkSuccess: action,

      getSuggestion: action,
    });
  }

  get isOnboarding() {
    // @ts-ignore
    return this.user?.metadata?.hasCompletedOnboarding !== true;
  }

  get hasTakenTour() {
    // @ts-ignore
    return this.user?.metadata?.hasTakenTour === true;
  }

  loadUserSuccess = (user: any) => {
    this.isLoading = false;
    this.user = user;
  };

  loadUserFailure = () => {
    this.isLoading = false;
  };

  loadUser = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      if (this.user) {
        this.isLoading = false;
        return resolve(this.user);
      }

      API.get("/api/user/me")
        .then(response => response.json())
        .then(data => {
          this.loadUserSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.loadUserFailure();
          return reject(error);
        });
    });
  };
  
  loadUserMetadataSuccess = (metadata) => {
    this.isLoading = false;
    this.metadata = metadata; 
  };

  loadUserMetadataFailure = () => {
    this.isLoading = false;
  };

  loadUserMetadata = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.get("/api/user/me")
        .then(response => response.json())
        .then(data => {
          if (data.metadata) {
            this.loadUserMetadataSuccess(data.metadata);
          } else {
            this.loadUserMetadataSuccess({});
          }
          return resolve(data.metadata);
        })
        .catch((error) => {
          this.loadUserMetadataFailure();
          return reject(error);
        });
    });
  };

  loadUserAccountsSuccess = ({ connectedAccounts } : { connectedAccounts: any[] }) => {
    this.isLoading = false;

    if (this.userAccounts) {
      // @ts-ignore because this.data is a mobx observable, not an actual array
      this.userAccounts.replace(connectedAccounts);
    } else {
      this.userAccounts = connectedAccounts;
    }
  };

  loadUserAccountsFailure = () => {
    this.isLoading = false;
  };

  loadUserAccounts = () => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.get("/api/user/accounts")
        .then(response => response.json())
        .then(data => {
          this.loadUserAccountsSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.loadUserAccountsFailure();
          return reject(error);
        });
    });
  };

  updateUserSuccess = (user: any) => {
    this.isLoading = false;
    Object.assign(this.user, user);
  };

  updateUserFailure = () => {
    this.isLoading = false;
  };

  updateUser = (userData: any) => {
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      API.post("/api/user/update", userData)
        .then(response => response.json())
        .then(data => {
          this.updateUserSuccess(data);
          return resolve(data);
        })
        .catch((error) => {
          this.updateUserFailure();
          return reject(error);
        });
    });
  };

updateUserMetadataFailure = () => {
    this.isLoading = false;
  };

updateUserMetadataSuccess = (metadata) => {
    this.isLoading = false;
    if (metadata) {
        // Update the store's metadata
        this.metadata = metadata;
        
        // Log the update for debugging
        console.log('UserStore metadata updated:', {
            previous: this.metadata,
            new: metadata
        });
    }
};

updateUserMetadata = (userMetaData) => {
    this.isLoading = true;
    console.log('Sending metadata update:', userMetaData); // Debug log

    return new Promise((resolve, reject) => {
        API.post("/api/user/updateMetadata", { 
            metadata: userMetaData 
        })
            .then(response => response.json())
            .then(data => {
                console.log('Received metadata update response:', data); // Debug log
                this.updateUserMetadataSuccess(data.metadata);
                return resolve(data);
            })
            .catch((error) => {
                console.error('Metadata update failed:', error); // Debug log
                this.updateUserMetadataFailure();
                return reject(error);
            });
    });
};




  sendFeedbackSuccess = () => {
    this.isLoading = false;
  };

  sendFeedbackFailure = () => {
    this.isLoading = false;
  };

  sendFeedback = (feedback: { message: string}) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.post("/api/feedback", Object.assign({ url: window.location.href }, feedback))
        .then(data => {
          this.sendFeedbackSuccess();
          return resolve(data);
        })
        .catch((error) => {
          this.sendFeedbackFailure();
          return reject(error);
        });
    });
  };

  uploadFileChunkSuccess = () => {
    this.isLoading = false;
  };

  uploadFileChunkFailure = () => {
    this.isLoading = false;
  };

  uploadFileChunk = (formData: any) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      API.postFiles("/api/user/upload", formData)
        .then(response => response.json())
        .then(data => {
          this.uploadFileChunkSuccess();
          return resolve(data);
        })
        .catch((error) => {
          this.uploadFileChunkFailure();
          return reject(error);
        });
    });
  };

  getSuggestion = (data: { key: string }) => {
    return new Promise((resolve, reject) => {
      API.post("/api/user/suggest", data)
        .then(response => response.json())
        .then(resolve)
        .catch(reject);
    });
  };

  getAccountData = (accountEmail: string) => {
    return new Promise((resolve, reject) => {
      API.post("/api/user/accountStats", { accountEmail })
        .then(response => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  requestPhoneVerification = (phone_number: string) => {
    return new Promise((resolve, reject) => {
      API.post("/api/user/phone/send-verification", { phone_number })
        .then(resolve)
        .catch(reject);
    });
  };

  sendPhoneVerification = (passcode: string) => {
    return new Promise((resolve, reject) => {
      API.post("/api/user/phone/verify", { passcode })
        .then(resolve)
        .catch(reject);
    });
  };

  afterCompleteTour = () => {
    // @ts-ignore
    this.user.metadata.hasTakenTour = true;
    this.metadata.hasTakenTour = true;
  }

  completeTour = () => {
    return API.post("/api/user/completeTour", {})
        .then(this.afterCompleteTour);
  }
}

const userStore = new UserStore();

export default userStore;
