import React from 'react';
import { inject, observer } from 'mobx-react';
import { toast } from "react-toastify";

import Modal from '../Modal';
import Button from '../Button';
import Steps from '../Steps';

import { WelcomImg, WelcomeContent } from './steps/Welcome';
import { StartTaskImg, StartTaskContent } from './steps/StartTask';
import { AddMemoryImg, AddMemoryContent } from './steps/AddMemory';
import { SmartTaskImg, SmartTaskContent } from './steps/SmartTask';
import { FeedbackImg, FeedbackContent } from './steps/Feedback';
import { BestResultContent, BestResultImg } from './steps/BestResult';

import '../../../index.scss';
import './Tour.scss';

const DELAY = 2_000;

type TourProps = {
    UserStore?: any;
    onFinish?: () => void;
}

type TourState = {
    page: number;
    clsName: string;
}

class Tour extends React.Component<TourProps, TourState> {
    state = {
        page: 0,
        clsName: 'fade-in'
    }

    onPrev = () => {
        const { page } = this.state;

        this.setState({ clsName: 'fade-out' }, () => {
            setTimeout(() => this.setState({ page: page - 1, clsName: 'fade-in' }), DELAY)
        });
    }

    onNext = () => {
        const { page } = this.state;

        this.setState({ clsName: 'fade-out' }, () => {
            setTimeout(() => this.setState({ page: page + 1, clsName: 'fade-in' }), DELAY)
        });
    }

    onFinish = () => {
        const { UserStore, onFinish } = this.props;

        if (onFinish) {
            return onFinish();
        }

        UserStore.completeTour()
            .catch(() => {
                toast.error(
                    "Oops! Something went wrong, please try again. If you continue to see this problem, please contact customer support.", 
                    { theme: 'dark', position: 'bottom-right' }
                );
            });
    }
    
    renderButtons = () => {
        const { page } = this.state;
        const NUM_PAGES = 5;

        const els = [];

        if (page === 0) {
            els.push(<div style={{ flex: 1 }} />);
            els.push(<Button onClick={this.onNext}>Continue</Button>);
            els.push(<div style={{ flex: 1 }} />);
        } else {
            els.push(<Button onClick={this.onPrev}>Previous</Button>);
            els.push(<div style={{ flex: 1 }} />);
            els.push(<Steps currentStep={page} totalSteps={6} />);
            els.push(<div style={{ flex: 1 }} />);

            if (page === NUM_PAGES) {
                els.push(<Button onClick={this.onFinish}>Finish</Button>);
            } else {
                els.push(<Button onClick={this.onNext}>Continue</Button>);
            }    
        }

        return (
            <div className='flex flex-row items-center' style={{ width: '100%' }}>
                {els}
            </div>
        );
    }

    renderContent = () => {
        const { page } = this.state;

        switch (page) {
            case 5:
                return { Img: SmartTaskImg, Content: SmartTaskContent };

            case 4:
                return { Img: FeedbackImg, Content: FeedbackContent };

            case 3:
                return { Img: BestResultImg, Content: BestResultContent };

            case 2:
                return { Img: StartTaskImg, Content: StartTaskContent };

            case 1:
                return { Img: AddMemoryImg, Content: AddMemoryContent };
            
            case 0:
            default:
                return { Img: WelcomImg, Content: WelcomeContent };
        }
    }

    render() {
        const { clsName } = this.state;
        const { Img, Content } = this.renderContent();

        return (
            <Modal cls="adapter-tour">
                <div className='flex flex-col items-center'>
                    <div className='mb-8'>
                        <Img cls={clsName} />
                    </div>

                    <Content cls={clsName} />

                    {this.renderButtons()}
                </div>
            </Modal>
        );
    }
}

export default inject("UserStore")(observer(Tour));