import React from 'react';
import Card from 'components/common/Card';

import { Text } from 'components/common/Typography';

import './Option.scss';

const MemoryOption = ({ text }: { text: string }) => {
    // const parts = text.split('\n');
    // const explanation = parts[0];
    // const rawArray = parts.length > 1 ? parts[1] : '[]';
    // const memories = JSON.parse(rawArray);
    
    // const els = [];

    // for (let i=0; i<memories.length; i++) {
    //     const m = memories[i];
    //     els.push(<li key={i}><Text fontWeight='light'>{m}</Text></li>);
    // }

    // return (
    //     <Card cls="adapter-memory">
    //         <Text fontWeight='light'>{explanation}</Text>

    //         {els.length > 0 && (<ul>{els}</ul>)}
    //     </Card>
    // );

    return (
        <Card cls="adapter-memory">
            <Text fontWeight='light'>{text}</Text>
        </Card>
    );
};

export default MemoryOption;