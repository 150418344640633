import React from 'react';

import './Typography.scss';

type Typography_Props = {
    fontWeight?: 'light' | 'medium' | 'bold';
    fontStyle?: 'regular' | 'italic';
    fontSize?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '10xl';
    title?: string;
    
    children: any;
    style?: any;
    cls?: string;
}

export const Text = ({ 
    children, 
    fontWeight = 'medium', 
    fontStyle = 'regular', 
    fontSize = 'm',
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <p
            className={`adapter-typography-text adapter-font-${fontWeight}-${fontStyle} adapter-text-size-${fontSize} ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </p>
    );
}

export const HeadText = ({ 
    children, 
    fontWeight = 'medium', 
    fontStyle = 'regular', 
    fontSize = 'm',
    title = '',
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <h2
            className={`adapter-typography-headtext adapter-font-${fontWeight}-${fontStyle} adapter-header-text-size-${fontSize} ${cls}`}
            style={style}
            title={title}
        >
            {children}
        </h2>
    );
}

export const UL = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <ul
            className={`adapter-ul ${cls}`}
            style={style}
        >
            {children}
        </ul>
    );
}

export const OL = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <ol
            className={`adapter-ol ${cls}`}
            style={style}
        >
            {children}
        </ol>
    );
}

export const LI = ({ 
    children, 
    style = {},
    cls = ''
}: Typography_Props) => {
    return (
        <li
            className={`adapter-li ${cls}`}
            style={style}
        >
            {children}
        </li>
    );
}

export const LINK = ({ 
    children, 
    style = {},
    cls = '',
    href = undefined,
    onClick = () => {}
}: any) => {
    return (
        <a
            className={`adapter-link ${cls}`}
            style={style}
            href={href}
            onClick={onClick}
        >
            {children}
        </a>
    );
}