// /frontend/src/stores/AtlasStore.ts

import { makeObservable, action, observable, runInAction } from "mobx";
import { 
    determineTileType, 
    getTileTypeName, 
    getTileTypeIcon,
    CATEGORY_MAPPINGS 
} from '../constants/CategoryMappings';
import API from "util/API";

export interface AtlasPreference {
    name: string;
    value: any;
    category?: string;
    metadata?: {
        confidence?: number;
        lastUpdated?: string;
        source?: string;
        [key: string]: any;
    };
}

export interface AtlasCategory {
    id: string;
    name: string;
    icon: string;
    type: string;
    description: string;
    tileTypes: Array<{
        id: string;
        name: string;
        icon: string;
        count?: number;
    }>;
}

export interface AtlasResponse {
    category: string;
    preferences: AtlasPreference[];
    suggestedText: string;
}

class AtlasStore {
    @observable categories: AtlasCategory[] = [
        {
            id: 'travel',
            name: 'Travel',
            icon: '✈️',
            type: 'Travel',
            description: 'Travel preferences and patterns',
            tileTypes: [
                { id: 'airline', name: 'Airlines', icon: '✈️' },
                { id: 'location', name: 'Locations', icon: '🏢' },
                { id: 'spend', name: 'Spend', icon: '💰' },
                { id: 'timing', name: 'Schedule', icon: '⏰' },
                { id: 'comfort', name: 'Comfort', icon: '💺' },
            ]
        },
        {
            id: 'dining',
            name: 'Dining',
            icon: '🍽️',
            type: 'Dining',
            description: 'Food and dining preferences',
            tileTypes: [
                { id: 'cuisine', name: 'Cuisines', icon: '🍳' },
                { id: 'spend', name: 'Spend', icon: '💰' },
                { id: 'timing', name: 'Schedule', icon: '⏰' },
                { id: 'location', name: 'Places', icon: '📍' }
            ]
        },
        {
            id: 'lodging',
            name: 'Lodging',
            icon: '🏨',
            type: 'Lodging',
            description: 'Accommodation preferences',
            tileTypes: [
                { id: 'accommodation', name: 'Property Types', icon: '🏨' },
                { id: 'comfort', name: 'Room & Amenities', icon: '🛏️' },
                { id: 'services', name: 'Services', icon: '🛎️' },
                { id: 'location', name: 'Location & Area', icon: '📍' },
                { id: 'spend', name: 'Rates & Budget', icon: '💰' },
                { id: 'loyalty', name: 'Hotel Programs', icon: '🎯' },
                { id: 'timing', name: 'Stay Patterns', icon: '⏰' },
                { id: 'social', name: 'Group & Events', icon: '👥' }
            ]
        }
    ];


    @observable preferences: Record<string, AtlasResponse> = {};
    @observable isLoading: boolean = false;
    @observable error: string | null = null;
    @observable selectedCategory: string | null = null;
    @observable selectedTileType: string | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    setSelectedCategory(category: string | null) {
        this.selectedCategory = category;
    }

    @action
    setSelectedTileType(tileType: string | null) {
        this.selectedTileType = tileType;
    }

    @action
    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    @action
    setError(error: string | null) {
        this.error = error;
    }

    @action
    setPreferences(category: string, data: AtlasResponse) {
        const formattedData: AtlasResponse = {
            category: data.category,
            preferences: data.preferences.map(pref => ({
                name: pref.name,
                value: pref.value,
                category: data.category,
                metadata: {
                    lastUpdated: pref.metadata?.lastUpdated,
                    source: pref.metadata?.source,
                    ...pref.metadata
                }
            })),
            suggestedText: data.suggestedText || ''
        };
        this.preferences[category] = formattedData;
    }

    @action
    async fetchAtlasPreference(category: string): Promise<AtlasResponse> {
        this.setLoading(true);
        this.setError(null);

        try {
            const response = await API.get(`/api/user/atlas/${category}`);
            const rawData = await response.json();

            const formattedData: AtlasResponse = {
                category: category,
                preferences: rawData.preferences.map((pref: any) => ({
                    name: pref.name,
                    value: pref.value,
                    category: category,
                    metadata: {
                        confidence: pref.metadata?.confidence || 0,
                        lastUpdated: pref.metadata?.lastUpdated || new Date().toISOString(),
                        source: pref.metadata?.source || 'Atlas',
                        ...pref.metadata
                    }
                })),
                suggestedText: rawData.suggestedText || ''
            };

            runInAction(() => {
                this.setPreferences(category, formattedData);
                this.setLoading(false);
            });

            return formattedData;
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to fetch preferences';
            
            runInAction(() => {
                this.setError(errorMessage);
                this.setLoading(false);
            });

            throw error;
        }
    }

    // Alias for backward compatibility
    fetchPreferences = this.fetchAtlasPreference;

    @action
    async updatePreference(category: string, preferenceId: string, value: any): Promise<void> {
        try {
            await API.put(`/api/user/atlas/${category}/${preferenceId}`, { value });
            await this.fetchAtlasPreference(category);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to update preference';
            this.setError(errorMessage);
            throw error;
        }
    }

    getPreferences(category: string): AtlasResponse {
        return this.preferences[category] || {
            category,
            preferences: [],
            suggestedText: ''
        };
    }

    getCategoryById(id: string): AtlasCategory | undefined {
        return this.categories.find(category => category.id === id);
    }

    getTileTypes(categoryId: string): AtlasCategory['tileTypes'] {
        return this.getCategoryById(categoryId)?.tileTypes || [];
    }

    getCurrentTileTypes = () => {
        const category = this.categories.find(
            c => c.id === this.selectedCategory
        );

        if (category && category.type !== 'Memories') {
            const cachedData = this.preferences[category.type]?.preferences;
            if (cachedData) {
                const tileTypes = new Map();
                cachedData.forEach(pref => {
                    const tileType = determineTileType(pref.name);
                    if (!tileTypes.has(tileType)) {
                        tileTypes.set(tileType, {
                            id: tileType,
                            name: getTileTypeName(tileType),
                            icon: getTileTypeIcon(tileType),
                            count: 0
                        });
                    }
                    tileTypes.get(tileType).count++;
                });
                return Array.from(tileTypes.values());
            }
        }

        return category?.tileTypes || [];
    };

    getPreferencesByTileType(category: string, tileType: string): AtlasPreference[] {
        const preferences = this.getPreferences(category).preferences;
        return preferences.filter(pref => determineTileType(pref.name) === tileType);
    }

    transformAtlasData = (data: AtlasResponse) => {
        return data.preferences.map((pref, index) => ({
            id: `atlas-${data.category}-${index}`,
            categoryId: '1',
            categoryType: data.category,
            tileType: determineTileType(pref.name),
            name: pref.name,
            value: pref.value,
            metadata: {
                ...pref.metadata,
                confidence: pref.metadata?.confidence,
                lastUpdated: pref.metadata?.lastUpdated,
                source: pref.metadata?.source
            }
        }));
    };

    getSelectedCategory() {
        return this.selectedCategory;
    }

    getSelectedTileType() {
        return this.selectedTileType;
    }

    isDataLoaded(category: string): boolean {
        return !!this.preferences[category];
    }

    clearPreferences() {
        this.preferences = {};
        this.selectedCategory = null;
        this.selectedTileType = null;
    }
}

export default new AtlasStore();
