import React from 'react';
import L from "leaflet";

import Button from 'components/common/Button';
import Pill from 'components/common/Pill';

import './RestaurantOption.scss';
import { convertTo12Hour } from 'util/Time';
import MatchComparisonBar from '../bar/MatchComparisonBar';
import { HeadText, Text } from 'components/common/Typography';
import { ThumbsUpIcon, LocationIcon } from 'components/common/Icon'

const MAP_LOCATION_COLOR = '#EF6820';

type RestaurantResult = {
    name: string;
    reserve_url: string;
    time: string;
    address: string;
    reviews?: number;
    reserve_source: string;
    price?: string;
    rating?: number;
    type?: string;
    types?: string[];
    order_online_url?: string;
    website?: string;
    gps_coordinates?: {
        lat: number;
        lng: number;
    }
}

type RestaurantOptionProps = {
    option: RestaurantResult;
    index: number;
    ranking_reasoning?: string;
    score?: number;
    allScores?: number[];
}

export class RestaurantOption extends React.Component<RestaurantOptionProps, { map: L.Map | null }> {
    private mapRef = React.createRef<HTMLDivElement>();

    state = {
        map: null as L.Map | null
    }

    componentDidMount(): void {
        this.drawMap();
    }

    drawMap = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        if (!this.state.map && lat && lng && this.mapRef.current) {
            let zoomScale = 16;

            const initializedMap = L.map(this.mapRef.current, {
                zoomControl: false,
                attributionControl: false,
            }).setView([lat, lng], zoomScale);

            const tileLayer = L.tileLayer(
                "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ).addTo(initializedMap);

            tileLayer.getContainer().style.filter = "grayscale(100%)";

            // @ts-ignore
            L.svg({ clickable: true }).addTo(initializedMap);

            L.circleMarker([lat, lng], {
                color: MAP_LOCATION_COLOR,
                fillColor: MAP_LOCATION_COLOR,
                fillOpacity: 0.5,
                radius: 4
            }).addTo(initializedMap);

            this.setState({ map: initializedMap });
        }
    };

    renderButton = () => {
        const { option } = this.props;

        return (
            <div className='flight-option-button-wrapper'>

                <div className='flight-option-price-display'>
                    <HeadText fontSize='s'>{option.price}</HeadText>
                </div>

                <Button
                    size="2xl"
                    disabled={!option.reserve_url}
                    onClick={() => window.open(option.reserve_url, '_blank')}
                    style={{ width: '100%' }}
                >Book Now</Button>
            </div>
        );
    }

    getRankingReason = () => {
        const { option, ranking_reasoning } = this.props;

        return ranking_reasoning ? (
            <div className="option-ranking-reason">
                <Text fontWeight='bold'>Details</Text>
                <Text fontSize='s'>{ranking_reasoning}</Text>
            </div>
        ) : <></>;
    }

    renderOptionDetails = () => {
        const { option } = this.props;
        const lat = option.gps_coordinates?.lat;
        const lng = option.gps_coordinates?.lng;

        return (
            <div className="option-details">
                {lat && lng && (
                    <div className="option-details-body-wrapper">
                        <div ref={this.mapRef} style={{ height: '300px', flex: 1 }}></div>
                    </div>
                )}

                <div className='flex flex-row self-stretch'>
                    <div className='flex-1 text-left'>
                        <Text fontSize='xs'>Reservation time</Text>
                    </div>
                    <div className='flex-1 text-right'>
                        <Text fontSize='xs'>Website</Text>
                    </div>
                </div>
                <div className='flex flex-row self-stretch'>
                    <div className='flex-1 text-left'>
                        <Text>{convertTo12Hour(option.time)}</Text>
                    </div>
                    <div className='flex-1 text-right'>
                        <Text>
                            <a href={option.website} target="_blank" rel="noopener noreferrer">
                                Visit Website
                            </a>
                        </Text>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { option, index } = this.props;
        const isTopPick = index === 0;

        return (
            <div className="adapter-intention-option">
                <div className="option-price-header">
                    <div className="option-price-header-wrapper">
                        <HeadText fontSize='xs'>{option.name}</HeadText>
                        <Text fontSize='s'>{option.type}</Text>

                        {option.address && (<Text fontSize='s'><LocationIcon />&nbsp;{option.address}</Text>)}

                        <Text fontSize='s'><ThumbsUpIcon />&nbsp;Avg. {option.rating} / {option.reviews} reviews</Text>
                    </div>

                    {isTopPick && <div className='absolute top-0 right-0'><Pill highlighted>Best Match</Pill></div>}
                </div>

                {this.renderOptionDetails()}

                {this.renderButton()}

                <MatchComparisonBar
                    score={this.props.score}
                    allScores={this.props.allScores}
                />

                {this.getRankingReason()}
            </div>
        );
    }
}

export default RestaurantOption;