// /frontend/src/pages/Atlas/PreferenceDetail.tsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Preference, HistoryItem, UnderstandingItem } from './types';

const Container = styled(motion.div)`
  background: white;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  margin: 24px;
  overflow: hidden;
  width: 300px;
`;

const Header = styled.div`
  padding: 24px;
  background: #F4F3FF;
  border-bottom: 1px solid #E6E3FF;
`;

const CategoryLabel = styled.div`
  color: #727271;
  font-family: "Rand Light", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
`;

const PreferenceName = styled.h1`
  font-size: 28px;
  font-family: "Rand Medium", sans-serif;
  color: #171717;
  margin: 8px 0;
`;

const PreferenceValue = styled.div`
  font-size: 20px;
  color: #333;
  font-family: "Rand Medium", sans-serif;
  margin-top: 8px;
`;

const ConfidenceIndicator = styled.div<{ confidence: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  padding: 8px 0;
`;

const ConfidenceBar = styled.div<{ confidence: number }>`
  width: 100px;
  height: 4px;
  background: #E6E3FF;
  border-radius: 2px;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => props.confidence * 100}%;
    background: ${props => {
        if (props.confidence >= 0.8) return '#4CAF50';
        if (props.confidence >= 0.5) return '#FFC107';
        return '#FF5722';
    }};
    border-radius: 2px;
    transition: width 0.3s ease;
  }
`;

const ConfidenceLabel = styled.span<{ confidence: number }>`
  font-size: 12px;
  font-family: "Rand Medium", sans-serif;
  color: ${props => {
    if (props.confidence >= 0.8) return '#4CAF50';
    if (props.confidence >= 0.5) return '#FFC107';
    return '#FF5722';
  }};
  transition: color 0.3s ease;
`;

const MetadataGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 24px;
  background: white;
`;

const MetadataCard = styled.div`
  padding: 16px;
  background: #F9F9F9;
  border-radius: 12px;
  border: 1px solid #EAEAEA;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  }
`;

const MetadataLabel = styled.div`
  font-size: 12px;
  color: #666;
  font-family: "Rand Light", sans-serif;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

const MetadataValue = styled.div`
  font-size: 14px;
  color: #333;
  font-family: "Rand Medium", sans-serif;
`;

const Section = styled.div`
  padding: 24px;
  border-top: 1px solid #EAEAEA;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  font-family: "Rand Medium", sans-serif;
  color: #171717;
  margin-bottom: 16px;
`;

const HistoryList = styled.div`
  display: grid;
  gap: 12px;
`;

const HistoryItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #F9F9F9;
  border-radius: 8px;
  font-size: 14px;
  transition: transform 0.2s ease;

  &:hover {
    transform: translateX(4px);
  }
`;

const UnderstandingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const UnderstandingCard = styled.div`
  padding: 16px;
  background: #F9F9F9;
  border-radius: 12px;
  border: 1px solid #EAEAEA;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  }
`;

const UnderstandingTitle = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  font-family: "Rand Light", sans-serif;
`;

const UnderstandingValue = styled.div`
  font-size: 16px;
  color: #333;
  font-family: "Rand Medium", sans-serif;
  margin-bottom: 4px;
`;

const DateText = styled.div`
  font-size: 12px;
  color: #999;
  font-family: "Rand Light", sans-serif;
`;

const getConfidenceLevel = (confidence: number): string => {
    if (confidence >= 0.8) return 'strong';
    if (confidence >= 0.5) return 'medium';
    if (confidence > 0) return 'budding';
    return 'unknown';
};

const formatConfidence = (confidence: number): string => {
    const level = getConfidenceLevel(confidence);
    return `${(confidence * 100).toFixed(0)}% - ${level.charAt(0).toUpperCase() + level.slice(1)}`;
};

export const PreferenceDetail: React.FC<{
    preference: Preference;
    history: HistoryItem[];
    understanding: UnderstandingItem[];
    onBack: () => void;
}> = ({ preference, history, understanding, onBack }) => {
    const confidence = preference.metadata?.confidence || 0;

    return (
        <Container
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
        >
            <Header>
                <CategoryLabel>{preference.categoryType}</CategoryLabel>
                <PreferenceName>{preference.name}</PreferenceName>
                <PreferenceValue>{preference.value}</PreferenceValue>
            </Header>

            <MetadataGrid>
                {preference.metadata && (
                    <MetadataCard>
                        <MetadataLabel>Last Updated</MetadataLabel>
                        <MetadataValue>
                            {new Date(preference.metadata.lastUpdated || Date.now()).toLocaleDateString()}
                        </MetadataValue>
                    </MetadataCard>
                )}
                {preference.metadata?.source && (
                    <MetadataCard>
                        <MetadataLabel>Source</MetadataLabel>
                        <MetadataValue>{preference.metadata.source}</MetadataValue>
                    </MetadataCard>
                )}
                {preference.location && (
                    <>
                        {preference.location.code && (
                            <MetadataCard>
                                <MetadataLabel>Location Code</MetadataLabel>
                                <MetadataValue>{preference.location.code}</MetadataValue>
                            </MetadataCard>
                        )}
                        {preference.location.city && (
                            <MetadataCard>
                                <MetadataLabel>City</MetadataLabel>
                                <MetadataValue>{preference.location.city}</MetadataValue>
                            </MetadataCard>
                        )}
                        {preference.location.state && (
                            <MetadataCard>
                                <MetadataLabel>State</MetadataLabel>
                                <MetadataValue>{preference.location.state}</MetadataValue>
                            </MetadataCard>
                        )}
                        {preference.location.country && (
                            <MetadataCard>
                                <MetadataLabel>Country</MetadataLabel>
                                <MetadataValue>{preference.location.country}</MetadataValue>
                            </MetadataCard>
                        )}
                    </>
                )}
                {preference.metadata && 
                    Object.entries(preference.metadata)
                        .filter(([key]) => !['confidence','lastUpdated', 'source'].includes(key))
                        .map(([key, value]) => (
                            <MetadataCard key={key}>
                                <MetadataLabel>{key.charAt(0).toUpperCase() + key.slice(1)}</MetadataLabel>
                                <MetadataValue>{value}</MetadataValue>
                            </MetadataCard>
                        ))
                }
            </MetadataGrid>

            {history.length > 0 && (
                <Section>
                    <SectionTitle>History</SectionTitle>
                    <HistoryList>
                        {history.map((item, index) => (
                            <HistoryItemContainer key={index}>
                                <span>{item.provider}</span>
                                <DateText>{new Date(item.date).toLocaleDateString()}</DateText>
                            </HistoryItemContainer>
                        ))}
                    </HistoryList>
                </Section>
            )}

            {understanding.length > 0 && (
                <Section>
                    <SectionTitle>Understanding</SectionTitle>
                  
                    <UnderstandingGrid>
                        {understanding
                            .filter(item => !item.title.toLowerCase().includes('confidence'))
                            .map((item, index) => (
                                <UnderstandingCard key={index}>
                                    <UnderstandingTitle>{item.title}</UnderstandingTitle>
                                    <UnderstandingValue>
                                        {item.type === 'percentage' 
                                            ? `${(Number(item.value) * 100).toFixed(0)}%`
                                            : item.value}
                                    </UnderstandingValue>
                                    {item.date && (
                                        <DateText>{new Date(item.date).toLocaleDateString()}</DateText>
                                    )}
                                </UnderstandingCard>
                            ))}
                    </UnderstandingGrid>
                </Section>
            )}
        </Container>
    );
};

export default PreferenceDetail;
