// /frontend/src/constants/CategoryMappings.ts
export interface CategoryMapping {
    keywords: string[];
    displayName: string;
    icon: string;
}

export interface CategoryMappings {
    [key: string]: CategoryMapping;
}

export const CATEGORY_MAPPINGS: CategoryMappings = {
    airline: {
        keywords: [
            'airline', 'carrier', 'flight', 'alliance', 'aircraft',
            'plane', 'aviation', 'airways', 'skyteam', 'oneworld',
            'star alliance', 'boarding', 'terminal', 'gate',
            'layover', 'connection', 'nonstop', 'direct',
            'domestic', 'international', 'transoceanic',
            'charter', 'codeshare', 'segment'
        ],
        displayName: 'Airlines',
        icon: '✈️'
    },
    spend: {
        keywords: [
            'budget', 'cost', 'price', 'fare', 'spend', 'rate',
            'expense', 'payment', 'charge', 'fee', 'pricing',
            'value', 'deposit', 'refund',
            'prepaid', 'package', 'deal', 'promotion', 'discount',
            'surge', 'peak', 'off-peak', 'seasonal rate',
            'nightly rate', 'per night', 'per diem', 'invoice',
            'transaction', 'billing', 'installment', 'subscription'
        ],
        displayName: 'Spending & Budget',
        icon: '💰'
    },
    timing: {
        keywords: [
            'schedule', 'departure', 'arrival', 'timing', 'pattern',
            'breakfast', 'lunch', 'brunch', 'dinner', 'drinks',
            'weekday', 'weekend', 'reservation', 'frequency',
            'check-in', 'check-out', 'duration', 'length of stay',
            'morning', 'afternoon', 'evening', 'night',
            'early', 'late', 'dawn', 'dusk', 'midnight',
            'hourly', 'daily', 'weekly', 'monthly', 'quarterly',
            'seasonal', 'holiday', 'peak season', 'off-season',
            'calendar', 'itinerary', 'availability', 'booking window',
            'advance notice', 'cancellation period', 'flexibility',
            'punctuality', 'delay', 'extension', 'overtime'
        ],
        displayName: 'Time & Schedule',
        icon: '⏰'
    },
    comfort: {
        keywords: [
            'seat', 'comfort', 'cabin', 'row', 'window',
            'aisle', 'middle', 'exit row', 'bulkhead',
            'recline', 'legroom', 'headroom', 'armrest',
            'temperature', 'climate', 'ventilation', 'air quality',
            'noise level', 'soundproof', 'quiet', 'peaceful',
            'spacious', 'cramped', 'cozy', 'ergonomic',
            'cushion', 'padding', 'support', 'adjustable',
            'accessible', 'mobility', 'assistance', 'wheelchair',
            'storage', 'overhead bin', 'under-seat'
        ],
        displayName: 'Comfort',
        icon: '💺'
    },
    cuisine: {
        keywords: [
            'cuisine', 'food', 'restaurant', 'bar', 'cafe', 'dining',
            'menu', 'dish', 'meal', 'appetizer', 'entree', 'dessert',
            'beverage', 'wine', 'cocktail', 'beer', 'spirit', 'dietary',
            'vegetarian', 'vegan', 'pescatarian', 'halal', 'kosher',
            'organic', 'gluten-free', 'dairy-free', 'nut-free',
            'spicy', 'mild', 'savory', 'sweet', 'umami',
            'fusion', 'traditional', 'modern', 'experimental',
            'michelin', 'gourmet', 'casual', 'fast-casual',
            'takeout', 'delivery', 'catering', 'buffet'
        ],
        displayName: 'Cuisines',
        icon: '🍳'
    },
    location: {
        keywords: [
            'location', 'address', 'destination', 'city', 'country', 'route', 'airport',
            'region', 'continent', 'hemisphere', 'timezone',
            'metropolitan', 'suburban', 'rural', 'urban',
            'waterfront', 'mountainside', 'lakefront', 'riverside',
            'coastline', 'island', 'peninsula', 'mainland',
            'north', 'south', 'east', 'west', 'central',
            'district', 'quarter', 'zone', 'precinct',
            'landmark', 'attraction', 'site', 'venue',
            'coordinates', 'latitude', 'longitude', 'elevation',
            'proximity', 'radius', 'perimeter', 'boundary'
        ],
        displayName: 'Locations',
        icon: '📍'
    },
    loyalty: {
        keywords: [
            'rewards', 'status', 'membership',
            'elite', 'tier', 'points', 'benefits',
            'perks', 'privileges', 'recognition', 'qualification',
            'milestone', 'achievement', 'anniversary', 'lifetime',
            'platinum', 'gold', 'silver', 'bronze',
            'premier', 'priority', 'exclusive', 'vip',
            'club', 'insider',
            'accumulation', 'redemption', 'transfer', 'conversion',
            'rollover', 'expiration', 'retention', 'upgrade'
        ],
        displayName: 'Loyalty Programs',
        icon: '🎯'
    },
    accommodation: {
        keywords: [
            'hotel', 'resort', 'lodging', 'accommodation', 'amenities',
            'property', 'vacation rental', 'boutique',
            'inn', 'villa', 'apartment', 'condo', 'cottage',
            'chalet', 'bungalow', 'mansion', 'penthouse',
            'hostel', 'motel', 'lodge', 'retreat',
            'residence', 'homestay', 'guesthouse', 'airbnb',
            'timeshare', 'all-inclusive', 'bed-breakfast',
            'camping', 'glamping', 'caravan', 'yacht',
            'room', 'suite', 'studio', 'apartment'
        ],
        displayName: 'Accommodation',
        icon: '🏨'
    },
    services: {
        keywords: [
            'concierge', 'valet', 'housekeeping', 'maintenance',
            'reception', 'front-desk', 'bellhop', 'porter',
            'shuttle', 'transfer', 'chauffeur', 'transportation',
            'laundry', 'dry-cleaning', 'pressing', 'tailoring',
            'spa', 'massage', 'wellness', 'fitness',
            'pool', 'jacuzzi', 'sauna', 'steam-room',
            'business-center', 'conference', 'meeting-room',
            'internet', 'wifi', 'broadband', 'connectivity',
            'security', 'surveillance', 'safe-deposit',
            'childcare', 'babysitting', 'pet-care', 'medical'
        ],
        displayName: 'Services',
        icon: '🛎️'
    },
    social: {
        keywords: [
            'party', 'group', 'gathering', 'meeting',
            'celebration', 'event', 'function', 'occasion',
            'solo', 'couple', 'family', 'friends',
            'business', 'corporate', 'team', 'delegation',
            'conference', 'convention', 'symposium', 'seminar',
            'wedding', 'anniversary', 'birthday', 'reunion',
            'private', 'public', 'exclusive', 'open',
            'networking', 'socializing', 'entertaining', 'hosting',
            'capacity', 'attendance', 'headcount', 'occupancy'
        ],
        displayName: 'Social & Events',
        icon: '👥'
    }
};

export function determineTileType(preferenceName: string): string {
    const lowerName = preferenceName.toLowerCase();
    
    for (const [type, mapping] of Object.entries(CATEGORY_MAPPINGS)) {
        if (mapping.keywords.some(keyword => lowerName.includes(keyword))) {
            return type;
        }
    }
    
    return 'Other';
}

export function getTileTypeName(type: string): string {
    return CATEGORY_MAPPINGS[type]?.displayName || type;
}

export function getTileTypeIcon(type: string): string {
    return CATEGORY_MAPPINGS[type]?.icon || '📋';
}

export function filterPreferencesByTileType(preferences: any[], tileType: string): any[] {
    return preferences.filter(pref => {
        const prefName = pref.name.toLowerCase();
        return CATEGORY_MAPPINGS[tileType]?.keywords.some(
            keyword => prefName.includes(keyword)
        ) || false;
    });
}
